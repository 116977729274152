import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { initializeApp } from 'firebase/app'; 
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Nav from '../Navbar/Nav';
import "../Servicii/servicii.css"
import Footer from "../Footer/Footer"

import "../ProjectDetails/detailsproject.css"

const ProjectDetails = () => {

  const firebaseConfig = {
    apiKey: "AIzaSyB8XIlbg4hGJj4lthImbvTtJOZC8dNDF4g",
    authDomain: "projects-v3.firebaseapp.com",
    projectId: "projects-v3",
    storageBucket: "projects-v3.appspot.com",
    messagingSenderId: "464029013720",
    appId: "1:464029013720:web:21346c59f4f31d8e18e9ad"
  };

  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const { projectId } = useParams(); // Preia projectId din URL
  const [images, setImages] = useState([]);
  const [projectData, setProjectData] = useState(null);
 
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const [showFooter ,setShowFooter ] = useState(true) 
  const [mainImage, setMainImage] = useState([]);

  const openImageModal = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
    setShowImageModal(true);
    setShowFooter(false)
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setShowFooter(true)
  };

  useEffect(() => {
    // Funcție async pentru a obține datele proiectului din Firestore
    const fetchProjectData = async () => {
      try {
        const projectDocRef = doc(firestore, 'post', projectId); // Schimbă 'post' cu colecția ta din Firestore
        const projectDocSnap = await getDoc(projectDocRef);

        if (projectDocSnap.exists()) {
          const projectData = projectDocSnap.data();
          setProjectData(projectData);
 // Dacă proiectul are un obiect 'images', atunci extrage imaginile
 if (projectData.mainImage && typeof projectData.mainImage === "object" && projectData.images && typeof projectData.images === 'object') {
  const sortedImagesArray = Object.keys(projectData.images)
    .sort() // Sortează cheile (numele) în ordine alfabetică
    .map(key => projectData.images[key]);
  
  const mainImageArray = Object.values(projectData.mainImage);
  setImages(sortedImagesArray);
  setMainImage(mainImageArray);

}
        } else {
          console.log('Documentul nu există');
        }
      } catch (error) {
        console.error('Eroare la preluarea datelor din Firestore:', error);
      }
    };

    fetchProjectData(); // Apelarea funcției pentru preluarea datelor
  }, [firestore, projectId]);

  if (!projectData) {
    // Afișează un mesaj sau componentă de încărcare în timp ce se încarcă datele
    return <div>Loading...</div>;
  }
  return (
    <>
    {showImageModal && (
        <div className="my-modal">
          <div className="body">
          <span className="close-modal" style={{ zIndex: "9999" }}  onClick={closeImageModal}>
          &times;
        </span>
          </div>
          <div className="container">
  <div className="row align-items-center">
    <div className="col-1 text-center order-1 ">
      <button className="left-button"> <i className="fa-solid fa-angle-left" style={{ fontSize: "2rem" }}></i></button>
    </div>
    <div className="col-10 order-2">
      <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        navigation={{ nextEl: ".right-button", prevEl: ".left-button" }}
        initialSlide={selectedImageIndex}
      >
           {mainImage.map((image, index) => (
          <SwiperSlide key={index} style={{ width: "100%", height: "100%" }}>
            <img src={image} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }}  loading='lazy'/>
          </SwiperSlide>
        ))}

        {images.map((image, index) => (
          <SwiperSlide key={index} style={{ width: "100%", height: "100%" }}>
            <img src={image} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }} loading='lazy' />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
    <div className="col-1 text-center order-3 ">
      <button className="right-button"> <i className="fa-solid fa-angle-right" style={{ fontSize: "2rem" }}></i></button>
    </div>
  </div>
</div>

        </div>
      )}
    <Nav/>
    <div id="portfolio-details" className="container mt-5 py-5">

      <div class="container d-flex flex-column align-items-center justify-content-between">
            <h3 className="text-center border-bottom border-info p-2 my-3 mb-5" style={{width:"fit-content" }} >{projectData.title}</h3>
            </div>
       
       {
        showFooter && (
          <div className="row gy-4">
          <div className="col-lg-12">
          <div className="row gy-4">
  
            <div className="col-sm-6 ">
            <div className="col-sm-12 background d-flex  align-items-center justify-content-center" style={{height:"400px" ,borderRadius:"15px"}}>
              <div className="portfolio-details-slider swiper ">
                <Swiper className="swiper-wrapper align-items-center"
                  // install Swiper modules
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={50}
                  slidesPerView={1}
                
                  autoplay={{ delay: 3000 }} 
      loop={true} 
      initialSlide={selectedImageIndex}
     
                  >
                    {mainImage.map((image, index) => (
                     <SwiperSlide key={index} style={{ width: "300px", height: "300px" }} >
                     <div className="d-flex align-items-center btn cursor-pointer justify-content-center" style={{ width: "100%", height: "100%" }}
                    onClick={() => openImageModal(0)}
                     >
                       <img src={image} alt={`Image ${index}`} style={{ objectFit: "contain", width: "90%", height: "100%" }}  loading='lazy'/>
                     </div>
                   </SwiperSlide>
                  ))}
                  {images.map((image, index) => (
                     <SwiperSlide key={index} style={{ width: "300px", height: "300px" }} >
                     <div className="d-flex align-items-center btn cursor-pointer justify-content-center" style={{ width: "100%", height: "100%" }}
                    onClick={() => openImageModal(index)}
                     >
                       <img src={image} alt={`Image ${index}`} style={{ objectFit: "contain", width: "100%", height: "100%" }}  loading='lazy'/>
                     </div>
                   </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              </div>
              <div className='col-sm-12 mt-4 text-center'>
            <a href={projectData.previewURL } className="btn btn-info">Vizualizare</a>
            </div>
           
            </div>
            <div className="col-sm-6 portfolio-info ">
            <div className="col-sm-12 background px-4 py-4" style={{height:"fit-content" ,borderRadius:"15px"}}>
              <h4 className='mb-3 pt-3 text-center'>Informații șablon</h4>
              
                <h5 className='mb-3'>Categorie: {projectData.industry}</h5>
               
             <h5 className='mb-3'>Descriere:</h5>
             <div>
  {projectData.description.split('\n').map((paragraph, index, array) => (
    <p key={index} className=''>
      {paragraph.trim() + (index < array.length - 1 ? '\n' : '')}
    </p>
  ))}
</div>

            </div>
            </div>
           
          </div>
          </div>
       </div>
        )
       }
    </div>
   {
    showFooter && (

      <Footer/>
    )
   }
  
    </>
  );
};

export default ProjectDetails;
