
import ParticleBackground from 'react-particle-backgrounds';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from "../src/Components/LandingPage/LandingPage"
import ProjectDetails from '../src/Components/ProjectDetails/ProjectDetails';
import Servicii from "../src/Components/Servicii/Servicii"
import  PortofoliuPage  from '../src/Components/PortofoliuPage/PortofoliuPage';
import ContactPage  from '../src/Components/ContactPage/ContactPage';

function App() {
  const settings = {
    particle: {
      particleCount: 50,
      color: "#0ef",
      minSize: 1,
      maxSize: 4
  
    },
    velocity: {
      minSpeed: 0.2,
      maxSpeed: 0.4
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.6,
      opacityTransitionTime: 10000
    },
 
 
  };

  const particleBackgroundStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  };
  
  return (
  <>
  <ParticleBackground settings={settings} style={particleBackgroundStyle} />
  <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<LandingPage />} />
            <Route  path="/servicii" element={<Servicii />} />
            <Route  path="/contact" element={<ContactPage />} />
            <Route path="/portofoliu" element={<PortofoliuPage/>}  />
            <Route path="/projectdetail/:projectId" element={<ProjectDetails/>}  />
          </Routes>
        </BrowserRouter>
  </>
    
  )
}

export default App;
