import React from 'react'
import { Link } from 'react-router-dom';
import { animateScroll } from 'react-scroll';
import webdesign from '../About/webdesign.png';
import mentenanta from '../About/mentenanta.png';
import actualizare from '../About/actualizare.png';

const ServicesPlans = () => {

  const servicii = [
    {
      name: 'Web Design',
      facilitate1:"Adaptabilitate la diferite ecrane",
      facilitate2:"O experiență de utilizare ușoară",
      facilitate3: "Design estestic",
      facilitate4:"Viteză de încărcare optimizată",
      img: webdesign,
      link: '/servicii',
    },
    {
      name: 'Actualizare',
      facilitate1:"Adăugarea conținutului nou",
      facilitate2:"Optimizarea performanței",
      facilitate3: "Extinderea funcționalității",
      facilitate4:"Eliminarea informațiilor depășite",
      img: actualizare,
      link: '/servicii',
    },
    {
      name: 'Mentenanță',
      facilitate1:"Suport tehnic eficient",
      facilitate2:"Securitate îmbunătățită",
      facilitate3: "Performanță optimizată",
      facilitate4:"Optimizare SEO",
      img: mentenanta,
      link: '/servicii',
    },
  ];

  return (
    <div class="services-plan py-5">
    <div class="container">
      <div class="row">
     {
        servicii.map((serviciu,index) => (

        <div class="col-lg-4" key={index}>
          <div class="card mb-5 mb-lg-0" style={{backgroundColor:"rgba(3, 3, 3, 0.5)" }}>
            <div class="card-body" >
              <h3 class=" text-center">{serviciu.name}</h3>
              <div className='text-center'>
              <img className='img-fluid ' src={serviciu.img} style={{maxHeight:"200px"}}/>
              </div>
              <hr/>
              <ul class="fa-ul">
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{serviciu.facilitate1}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{serviciu.facilitate2}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{serviciu.facilitate3}</li>
                    <li><span class="fa-li"><i class="fas fa-check"></i></span>{serviciu.facilitate4}</li>
               
              </ul>
              <div class="col text-center">
                <Link to={serviciu.link} class="btn btn-info" onClick={() => animateScroll.scrollToTop({ duration: 50 })}>Mai multe..</Link>
              </div>
            </div>
          </div>
        </div>
        )
     
         )
        }
     
      </div>
    </div>
  </div>
  )
}

export default ServicesPlans