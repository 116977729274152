import React, { useState, useEffect } from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { animateScroll } from 'react-scroll';



import '../Navbar/nav.css';
import { Link } from 'react-router-dom';
import ModalPopUp from '../Modal/Modal';

const Nav = () => {
 

  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // Adăugați starea meniului mobil

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const navItems = [
    { navItem: 'Acasă', link: '/', style: 'active-nav', id: 1 },
    { navItem: 'Servicii', link: '/servicii', style: 'about-nav', id: 2 },
    { navItem: 'Portofoliu', link: '/portofoliu', style: 'resume-nav', id: 3 },
    { navItem: 'Contact', link: '/contact', style: 'portfolio-nav', id: 4 },
   
  ];

  const handleMobileMenuItemClick = (link) => {
    setIsMobile(false); // Închideți meniul mobil
    const targetElement = document.getElementById(link);
  
    if (targetElement) {
      // Utilizați funcția de derulare (scroll) din react-scroll pentru a naviga la secțiunea corespunzătoare
      scroll.scrollTo(targetElement.offsetTop - 60, {
        smooth: true,
        duration: 1000,
      });
    }
  };


  return (
    <>
    <div className={`nav ${isScrolled ? 'scrolled' : ''}`} id='nav'>
      <h4>
        <Link to='/' className='logo' onClick={() => animateScroll.scrollToTop({ duration: 50 })}>
          Smart Web Lab.
        </Link>
      </h4>
     
      <ul className={isMobile ? 'navbar-mobile' : 'navbar'}>
        {navItems.map((item) => (
          <li key={item.id} className='nav-items'>
            <Link to={item.link}  onClick={() => animateScroll.scrollToTop({ duration: 50 })}>
            <span
              // Folosiți funcția handleMobileMenuItemClick când faceți clic pe un element de meniu
              onClick={() => handleMobileMenuItemClick(item.link)}
              className={item.style}
            >
              {item.navItem}
            </span>
            </Link>
          </li>
        ))}
        <li className='nav-items' id="cotaie"><ModalPopUp/></li>
      </ul>

      <button
        className='mobile-menu-icon'
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? (
          <i className='fas fa-times'></i>
        ) : (
          <i className='fas fa-bars'></i>
        )}
      </button>
      
    </div>
    
    </>
  );
};

export default Nav;
