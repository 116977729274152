import React from 'react';
import ModalPopUp from "../Modal/Modal"
import ModalWhatsApp from '../Modal/ModalWhatsApp'


const Footer = () => {

  return (
    <>
      <div className="container mt-1 bg-transparent " id='contact' style={{paddingTop:"2rem"}}>
        <div>
              <div className="section-title ">
                <h2 >Ai un proiect?</h2>
                <h3 className='fw-bold'>Contactează-ne</h3>
              </div>
          <div className='row align-items-center'>
            <div className="col-sm-8 ">
              <div>
                <p  className="background  p-3" style={{borderRadius:"20px"}}>Oferim servicii de înaltă calitate, desfășurate exclusiv online. Echipa noastră lucrează în mod 100% remote, asigurându-vă soluții eficiente și flexibile, oriunde vă aflați.</p>
              </div>
           

            </div>

            <div className="col-sm-4 mt-2 text-center  mb-5  slideLeft3" >
             <div className='mb-3'>
              <ModalPopUp  />

             </div>
             <div>

              <ModalWhatsApp />
             </div>
            </div>
          </div>
          <div className="row gy-4">

    <div className="col-lg-12">

      <div className="row gy-4">
       

        <div className="col-sm-4">
        <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center  text-center background" style={{borderRadius:"20px", height:"150px"}}>
            <h5 className='mt-3'>Program</h5>
            <p>Luni - Vineri</p>
            <i className="bi bi-clock" style={{marginTop:"-15px",fontSize:"1.5rem"}}></i>
            <p>09:00 - 17:00</p>
          </div>
        </div>

        <div className="col-sm-4">
          <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center text-center background" style={{borderRadius:"20px", height:"150px"}} >
            <h5 className='mt-2'>Email </h5>
            <i className="bi bi-envelope" style={{fontSize:"1.5rem"}}></i>
             <p>site@smartweblab.ro</p>
             
          </div>
        </div> 

        <div className="col-sm-4">
        <div className="col-sm-12 d-flex flex-column align-items-center justify-content-center text-center background" style={{borderRadius:"20px", height:"150px"}} >
            <h5 className='mt-2'>Telefon</h5>
            <i className="bi bi-telephone" style={{fontSize:"1.5rem"}}></i>
            <a href="tel:+40745015727"> 
          <p>+40 745 015 727</p>
          </a>
          
          </div>
        </div>

      </div>

    </div>
  

   

  </div>
          <div className="d-flex flex-column flex-sm-row justify-content-center border-top border-info py-4 mt-5 " >
            <p>&copy; 2023 Smart Web Lab. Toate drepturile rezervate</p>
            
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;

