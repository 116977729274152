import React from "react";
import "../Hero/hero.css";
import img  from "./heroswl.png"
import { animateScroll } from 'react-scroll';
import {Link} from "react-router-dom"


const Hero = () => {

  return (
    <>
    <div className="container-fluid d-flex vh-100 justify-content-center align-items-center text-center " id="home">
      <div className="row flex-lg-row align-items-center  ">
        <div className="col-sm-6 text-center ">
          <img src={img} className="img-fluid  "  alt="Smart Web Lab"  />
        </div>
        <div className="col-sm-6 ">
          <h1 className="display-5 fw-bold lh-1 mt-3 name text-center">Smart Web Lab</h1>
          <h5 className=" lh-1 mt-3 text-center job">Agenție de creare website-uri</h5>
          <div className=" social-links">
        
          <Link to="/portofoliu" className="btn btn-info mt-3 fw-bold" onClick={() => animateScroll.scrollToTop({ duration: 50 })}>Vezi portofolio </Link>
          </div>
        </div>
      </div>
    </div>

   




    </>
  );
};

export default Hero;
