import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalWhatsApp() {
  const [show, setShow] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const handleClose = () => {
    setShow(false);
    setIsSent(false);
  };
  const handleShow = () => setShow(true);

  const form = useRef();

  const sendWhatsAppMessage = () => {
    // Obțineți valorile din formular
    const name = form.current.user_name.value;
    const company = form.current.user_company.value;
    const email = form.current.user_email.value;
    const phone = form.current.user_phone.value;
    const message = form.current.message.value;

    // Construiți mesajul pentru WhatsApp
    const whatsappMessage = `Buna SWL, doresc un proiect
    Datele mele de contact sunt:
    Numele meu:${name},
    Email:${email},
    Telefon:${phone},
    Compania:${company}
    ${message}
    `

    // Construiți URL-ul de partajare pentru WhatsApp
    const whatsappUrl = `https://wa.me/40753020568?text=${encodeURIComponent(whatsappMessage)}`;

    // Redirecționați către URL pentru a deschide WhatsApp
    window.location.href = whatsappUrl;

    // Afișați un mesaj de succes
    setIsSent(true);
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <>
      <Button onClick={handleShow} variant="info">
        Mesaj WhatsApp
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title className="text-dark" style={{ fontWeight: 'bold' }}>
            Contactează-ne
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-dark text-bold">
          <div className="row">
            <form ref={form}>
              <div className="col-12 mb-3">
                <label htmlFor="name">Nume și Prenume</label>
                <input type="text" id="name" name="user_name" className="form-control" required />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="companie">Denumire Companie</label>
                <input type="text" id="companie" name="user_company" className="form-control" required />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="user_email" className="form-control" required />
              </div>
              <div className="col-12 mb-3">
                <label htmlFor="tel">Telefon</label>
                <input type="text" id="tel" name="user_phone" className="form-control" required />
              </div>
              <div className="col-12">
                <label htmlFor="mesaj">Mesaj</label>
                <textarea
                  type="text"
                  rows={5}
                  id="mesaj"
                  className="form-control"
                  name="message"
                  placeholder="Povestește-ne despre proiectul tău.."
                  required
                ></textarea>
              </div>
            </form>
          </div>
          <div className="col-12 mt-2">
            <button className="btn btn-success w-100" onClick={sendWhatsAppMessage}>
              Trimite pe WhatsApp
            </button>
          </div>
          {isSent && (
            <div className="alert alert-success mt-3">
              Se deschide aplicația WhatsApp!
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Închide
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalWhatsApp;
