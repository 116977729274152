import "../About/about.css";
import React from "react";
import Slider from "../Slider/Slider";
import ServicesPlans from "./ServicesPlans";

const About = () => {
  return (
    <>
      <div className="about section-show" id="about">
        <div className="about-me container">
          <div className="row">
            <div className="col-sm-10 mb-4 ">
              <div className="section-title pt-5">
                <h2>Despre noi</h2>
                <p>Smart Web Lab</p>
              </div>
            </div>

            <div
              className="col-lg-12 pt-4 pt-lg-0 content"
              data-aos="fade-left"
            >
              <div
                className="col background  py-3 px-3 "
                style={{ borderRadius: "20px", lineHeight: "1.2" }}
              >
                <p className="">
                  Suntem experți în dezvoltarea web, cu ani de experiență în
                  spate. Suntem dedicați să vă oferim cele mai bune soluții web,
                  adaptate nevoilor dvs. Nu suntem doar dezvoltatori web, suntem
                  și consultanți. Echipa noastră este aici pentru a vă ajuta să vă atingeți
                  obiectivele online și să vă construiți prezența digitală. La
                  Smart Web Lab, nu dezvoltăm doar site-uri web, creăm
                  experiențe online.
                </p>
                <p>
                  Vă putem ghida în luarea celor mai bune decizii pentru
                  afacerea dvs. online.
                </p>
                <p>
                  Servicile oferite de noi le găsiți mai jos.
                </p>
                <p>Contactați-ne astăzi pentru a discuta despre
                  proiectul dvs. și pentru a vedea cum vă putem ajuta să vă
                  atingeți obiectivele online.</p>
              </div>
            </div>
            <div className="col-12 mt-5 pt-5 ">
     <ServicesPlans/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
